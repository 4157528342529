import { createApp } from 'vue'
import App from './App.vue'
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

const toastOptions: PluginOptions = {
    // One of the options
    position: POSITION.BOTTOM_CENTER
};
app.use(Toast, toastOptions);

app.mount('#app');