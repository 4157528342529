<template>
  <h1>Sudoku</h1>
  <form id="searchbox" v-on:submit.prevent="onSubmit">
    <table id="sudoku" v-if="input.length > 0">
      <tbody>
        <tr v-for="(row, i) in input" v-bind:key="i">
          <td v-for="(column, k) in row" v-bind:key="k" class="input-square">
            <input size="1" maxlength="1" name="S{{ i }}{{ k }}" class="square" v-model="input[i][k]">
          </td>
        </tr>
      </tbody>
    </table>
    <button id="submit" class="smart" type="submit" name="smart" @click="getData()">
      Solve
    </button>
  </form>
  <table id="result" v-if="result">
    <h2>Result</h2>
    <table id="sudoku">
      <tbody>
        <td v-if="!Object.entries(result).length"><b>None</b></td>
        <tr v-for="row in result" v-bind:key="row">
          <td v-for="element in row" v-bind:key="element" class="result-square">
            <p size="1" maxlength="1" class="square">{{ element }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </table>
</template>

<script lang="ts">
import { AxiosResponse, AxiosError } from 'axios'
import { useToast } from "vue-toastification";
import { Options, Vue } from 'vue-class-component';
import SudokuService from '@/services/SudokuService';

@Options({
  props: {
    msg: String
  }
})
export default class SudokuInput extends Vue {
  input: Array<Array<string>> = [];
  result: any = null;
  toast: any;

  created() {
    this.toast = useToast();
    this.input = [...Array(9)].map(() => [...Array(9)].map(() => ''));
    console.log(this.input);
    // this.input = [];
  }

  getData() {
    console.log(this.input);
    SudokuService.getSudoku(this.input).then((response: AxiosResponse) => {
      this.result = response;
      if (!Object.entries(response).length) {
        this.toast.warning('No results.');
      }
    }).catch((e: AxiosError) => {
      if (e.response!.status === 400) {
        this.toast.warning(e.response!.data);
      }
      console.log(e);
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
