
import { AxiosResponse, AxiosError } from 'axios'
import { useToast } from "vue-toastification";
import { Options, Vue } from 'vue-class-component';
import SudokuService from '@/services/SudokuService';

@Options({
  props: {
    msg: String
  }
})
export default class SudokuInput extends Vue {
  input: Array<Array<string>> = [];
  result: any = null;
  toast: any;

  created() {
    this.toast = useToast();
    this.input = [...Array(9)].map(() => [...Array(9)].map(() => ''));
    console.log(this.input);
    // this.input = [];
  }

  getData() {
    console.log(this.input);
    SudokuService.getSudoku(this.input).then((response: AxiosResponse) => {
      this.result = response;
      if (!Object.entries(response).length) {
        this.toast.warning('No results.');
      }
    }).catch((e: AxiosError) => {
      if (e.response!.status === 400) {
        this.toast.warning(e.response!.data);
      }
      console.log(e);
    });
  }
}
